import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'
import { Icon } from 'semantic-ui-react'

const UserManualsNavBar = () => (
  <>
    <div className="container-fluid px-0 mt-4">
      <h1 className="text-center pt-4">MANUALS & DOWNLOADS</h1>
      <ul
        className={classNames(
          'nav-bar',
          'd-flex flex-wrap  align-items-center justify-content-center px-0'
        )}
      >
        <li className={classNames('nav-bar-item', 'mb-4')}>
          <Link
            to="/manuals-and-downloads/novo"
            activeClassName="active-nav-bar-item"
            className="nav-bar-link"
          >
            NOVO
          </Link>
        </li>
        <li className={classNames('nav-bar-item', 'mb-4')}>
          <Link
            to="/manuals-and-downloads/novo-x"
            activeClassName="active-nav-bar-item"
            className="nav-bar-link"
          >
            NOVO-X
          </Link>
        </li>
        <li className={classNames('nav-bar-item', 'mb-4')}>
          <Link
            to="/manuals-and-downloads/tergo"
            activeClassName="active-nav-bar-item"
            className="nav-bar-link"
          >
            TERGO
          </Link>
        </li>
        <li className={classNames('nav-bar-item', 'mb-4')}>
          <Link
            to="/manuals-and-downloads/magna"
            activeClassName="active-nav-bar-item"
            className="nav-bar-link"
          >
            MAGNA
          </Link>
        </li>
        <li className={classNames('nav-bar-item', 'mb-4')}>
          <Link
            to="/manuals-and-downloads/v-sport-t"
            activeClassName="active-nav-bar-item"
            className="nav-bar-link"
          >
            V-SPORT-T
          </Link>
        </li>
        <li className={classNames('nav-bar-item', 'mb-4')}>
          <Link
            to="/manuals-and-downloads/v-sport-n"
            activeClassName="active-nav-bar-item"
            className="nav-bar-link"
          >
            V-SPORT-N
          </Link>
        </li>
        <li className={classNames('nav-bar-item', 'mb-4')}>
          <Link
            to="/manuals-and-downloads/vip"
            activeClassName="active-nav-bar-item"
            className="nav-bar-link"
          >
            VIP
          </Link>
        </li>
        <li className={classNames('nav-bar-item', 'mb-4')}>
          <Link
            to="/manuals-and-downloads/majestic"
            activeClassName="active-nav-bar-item"
            className="nav-bar-link"
          >
            MAJESTIC
          </Link>
        </li>
      </ul>
    </div>
    <div className="d-flex justify-content-center mb-4 pb-4">
      <a
        href="https://contegospadesigns.sharepoint.com/:b:/s/web/EekhoZKzlHdOofoz0KrNJEIBKiRyXiL3YWPRFnl6MccyvA?e=lCyV9l"
        target="_blank"
        rel="noopener noreferrer"
        download
        className="compact-action-btn rounded-pill text-center"
      >
        <Icon name="download" /> Unpacking & Hookup Procedure
      </a>
    </div>
  </>
)

export default UserManualsNavBar
