import React from 'react'
import { Icon, Table } from 'semantic-ui-react'

const ChairDisplay = ({ imgLink, model, fileLinks }) => {
  // const [SystemDiagram, UserManual] = fileLinks

  return (
    <div className="row d-flex justify-content-center align-items-center py-4 px-5 bg-light">
      <div className="col-md-4 py-4 animated bounceInLeft slow">
        <img src={imgLink} alt="novo chair" className="img-fluid" />
      </div>
      <div className="col-md-8 d-flex flex-column justify-content-center py-4 animated bounceInRight slow">
        <div>
          <h1>{model} SPECIFICATIONS</h1>
          <div className="d-flex flex-wrap ">
            {/* <a
              target="_blank"
              rel="noopener noreferrer"
              href={SystemDiagram}
              download
              className="compact-action-btn mr-1 mb-1 rounded-pill text-center"
            >
              <Icon name="download" /> System Diagram
            </a> */}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={fileLinks}
              download
              className="compact-action-btn mr-1 mb-1 rounded-pill text-center"
            >
              <Icon name="download" /> User Manual
            </a>
          </div>
        </div>
        <Table celled striped>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <p>
                  <strong className="text-secondary">Shipping Specs</strong>
                </p>
                <p>42” Length x 56” Width x 60”Height (1 Pallet)</p>
                <p>107cm Length x142cm Width x 152cm Height</p>
                <p>330 lb. (pounds)</p>
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>
                <p>
                  <strong className="text-secondary">Tub Capacity</strong>
                </p>
                <p>4.5 US Gallons (17 Liters)</p>
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>
                <p>
                  <strong className="text-secondary">Switch</strong>
                </p>
                <p>On/Off Air Activated</p>
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>
                <p>
                  <strong className="text-secondary">Jet System</strong>
                </p>
                <p>
                  <strong>100% Disposable CONTÉGO Jet-Liner</strong>
                </p>
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>
                <p>
                  <strong className="text-secondary">Power Source</strong>
                </p>
                <p>120 VAC, 60Hz, 3 AMP</p>
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>
                <p>
                  <strong className="text-secondary">Plumbing</strong>
                </p>
                <p>
                  <strong>Hot/Cold Water:</strong> 3/8” FIP Fittings
                </p>
                <br />
                <p>
                  <strong>FIP Fittings Drain:</strong> 1” PVC Slip Male Fitting
                </p>
                <br />
                <p>
                  <strong>Optional Drain Pump:</strong> 3/4” PVC Slip Female Fitting (Additional
                  Purchase)
                </p>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <p>
                  <strong className="text-secondary">Air Vent System</strong>
                </p>
                <p>
                  <strong>2” Diameter Air Hose (ADDITIONAL PURCHASE)</strong>
                </p>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </div>
  )
}
export default ChairDisplay
